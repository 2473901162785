import { useEffect, useRef, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useNavigate } from "react-router-dom";
import { Stack, Pagination } from "@mui/material";
import { HttpMainApi } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";
import "./MyTotalList.css";
import * as XLSX from "xlsx";
interface propsType {
  userState: userState;
}
interface DataRow {
  sk: string;
  category: string;
  title: string;
  press_status: string;
  user_name: string;
  user_company: string;
  hongbo_user_name: string;
  create_time: string; // 또는 Date, 필요에 따라 적절한 타입 사용
}

const mainApi = new HttpMainApi();

const MyTotalList = (props: propsType) => {
  const navigate = useNavigate();
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const [fromDate, setFromDate] = useState<Dayjs | null>(
    dayjs().add(-60, "day")
  );
  const [toDate, setToDate] = useState<Dayjs | null>(dayjs());

  const [isAdmin, setIsAdmin] = useState(false);
  const [ingList, setIngList] = useState<any>([]);
  const [allIngList, setAllIngList] = useState<any>([]);
  const [question, setQuestion] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOption, setSelectedOption] = useState("mydata");
  const [selectedQuestionOption, setSelectedQuestionOption] = useState("title");
  const [selectedStatsOption, setSelectedStatsOption] = useState("all");
  const [listToRender, setListToRender] = useState<any[]>([]);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(-1);

  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "ascending" | "descending";
  } | null>(null);

  function sortData(data: any[]) {
    if (!sortConfig) return data; // 정렬 설정이 없으면 원본 데이터 반환

    return [...data].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
  }

  function requestSort(key: string) {
    let direction: "ascending" | "descending" = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    // console.log(key);
    // console.log(direction);

    setSortConfig({ key, direction });
  }

  const sortedData = sortData(listToRender);

  useEffect(() => {
    if (isAdmin) {
      get_all_total_list();
    } else {
      get_regular_user_list();
    }
    return () => {
      setIsLoading(false);
      setIngList([]);
      setIsAdmin(false);
      setAllIngList([]);
      setFromDate(null);
      setToDate(null);
    };
  }, []);

  const get_my_total_list = async () => {
    const param: any = {
      command: "get_my_total_list",
      user_id: props.userState.id,
      from_date: dayjs(fromDate).format("YYYYMMDD"),
      to_date: dayjs(toDate).add(1, "day").format("YYYYMMDD"),
      sortForward: false,
    };

    const res = await mainApi.post(param);

    if (res.code === "200") {
      setIngList(res.response.work_list);
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  const get_regular_user_list = async () => {
    const param: any = {
      command: "get_regular_user_list",
      user_id: props.userState.id,
      from_date: dayjs(fromDate).format("YYYYMMDD"),
      to_date: dayjs(toDate).add(1, "day").format("YYYYMMDD"),
      sortForward: false,
    };
    const res = await mainApi.post(param);

    if (res.code === "200") {
      setIngList(res.response.work_list);
    } else {
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  const get_all_total_list = async () => {
    setIsLoading(true);
    const param: any = {
      command: "get_all_total_list",
      user_id: props.userState.id,
      from_date: dayjs(fromDate).format("YYYYMMDD"),
      to_date: dayjs(toDate).add(1, "day").format("YYYYMMDD"),
      sortForward: false,
      selectedStatsOption: selectedStatsOption,
    };
    const res = await mainApi.post(param);

    if (res.code === "200") {
      setIsLoading(false);
      setAllIngList(res.response.work_list);
    } else {
      setIsLoading(false);
      console.error(res.response.error_msg);
      toastRef.current?.toast("" + res.response.error_msg, "error", 4000, {
        vertical: "top",
        horizontal: "center",
      });
    }
  };

  useEffect(() => {
    if (isAdmin) get_all_total_list();
    if (isAdmin) setSelectedOption("alldata");
  }, [isAdmin]);

  useEffect(() => {
    setIsAdmin(props.userState.isAdmin);
  }, [props.userState.isAdmin]);

  const handleSelectOptionChange = (event: any) => {
    setCurrentPage(1);
    setSelectedQuestionOption(event.target.value);
  };

  const handleSelectStatsChange = (event: any) => {
    setCurrentPage(1);
    setSelectedStatsOption(event.target.value);
  };

  useEffect(() => {
    switch (selectedOption) {
      case "mydata":
        setListToRender(ingList);
        break;
      case "alldata":
        setListToRender(allIngList);
        break;
      default:
        setListToRender([]);
    }
  }, [selectedOption, ingList, allIngList]);

  useEffect(() => {
    let temp_list = [];
    if (selectedOption === "mydata") {
      temp_list = ingList;
    } else if (selectedOption === "alldata") {
      temp_list = allIngList;
    }
    switch (selectedStatsOption) {
      case "all":
        break;
      case "CREATE":
        temp_list = temp_list.filter(
          (item: any) => item.press_status === "CREATE"
        );
        break;
      case "HONGBO_ING":
        temp_list = temp_list.filter(
          (item: any) =>
            item.press_status === "HONGBO_ING" &&
            (item.hongbo_user_name === undefined ||
              item.hongbo_user_name === "")
        );

        break;
      case "HONGBO_OK":
        temp_list = temp_list.filter(
          (item: any) =>
            item.press_status === "HONGBO_ING" && item.hongbo_user_name !== ""
        );

        break;

      case "RETURNED":
        temp_list = temp_list.filter(
          (item: any) => item.press_status === "RETURNED"
        );

        break;
      case "REJECTED":
        temp_list = temp_list.filter(
          (item: any) => item.press_status === "REJECTED"
        );

        break;
      case "COMPLETE":
        temp_list = temp_list.filter(
          (item: any) => item.press_status === "COMPLETE"
        );

        break;
      default:
        setListToRender([]);
    }

    if (question === "") {
      setListToRender(temp_list);
    } else {
      const filteredList = temp_list.filter((item: any) => {
        const value = item[selectedQuestionOption];
        return value && typeof value === "string" && value.includes(question);
      });
      setListToRender(filteredList);
    }

    setCurrentPage(1);
  }, [
    selectedStatsOption,
    selectedQuestionOption,
    ingList,
    allIngList,
    question,
  ]);

  function formatCreateTime(createTime: string): string {
    // 'create_time'에서 연, 월, 일을 추출
    const year = createTime.substring(0, 4);
    const month = createTime.substring(4, 6);
    const day = createTime.substring(6, 8);

    // 포맷된 날짜 문자열 반환
    return `${year}-${month}-${day}`;
  }

  const statusTextMap: any = {
    CREATE: "작성중",
    RETURNED: "반려",
    REJECTED: "보완 요청",
    HONGBO_ING: "홍보실 접수대기",
    COMPLETE: "완료",
  };

  // 페이지 변경
  const NOTICE_PER_PAGE = 10;

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    // fromDate와 toDate가 모두 유효한 Dayjs 객체이고, YYYY-MM-DD 형식을 충족하는지 확인
    const isFromDateValid =
      fromDate &&
      fromDate.isValid() &&
      fromDate.format("YYYY-MM-DD") === fromDate.format("YYYY-MM-DD");
    const isToDateValid =
      toDate &&
      toDate.isValid() &&
      toDate.format("YYYY-MM-DD") === toDate.format("YYYY-MM-DD");
    // 둘 다 유효한 경우에만 get_hongbo_data_list 함수 실행
    if (isFromDateValid && isToDateValid) {
      if (isAdmin) {
        get_all_total_list();
      } else {
        get_regular_user_list();
      }
    }
  }, [fromDate, toDate]);

  const downloadExcel = (dataList: any[], columns: any[]) => {
    // 각 행에 대해 엑셀 파일에 포함될 값을 계산
    const dataForExcel = dataList.map((row) => {
      let newRow: { [key: string]: any } = {};
      columns.forEach((col) => {
        // valueGetter가 정의된 경우 해당 로직을 사용하여 값을 계산
        if (col.valueGetter) {
          newRow[col.headerName] = col.valueGetter({ row });
        } else {
          newRow[col.headerName] = row[col.field];
        }
      });
      return newRow;
    });

    // 여기서부터는 이전 예시와 동일하게 xlsx 라이브러리를 사용하여 엑셀 파일 생성
    const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(
      workbook,
      "data(" + fromDate?.format("YYYY-MM-DD") + ").xlsx"
    );
  };

  const columns = [
    { headerName: "순번", field: "sk" },
    { headerName: "카테고리", field: "category" },
    { headerName: "기사제목", field: "title" },
    {
      headerName: "진행사항",
      field: "press_status",
      valueGetter: ({ row }: { row: DataRow }) =>
        row.press_status === "HONGBO_ING" && row.hongbo_user_name
          ? "홍보실 접수"
          : statusTextMap[row.press_status] || "상태 정보 없음",
    },
    { headerName: "작성자", field: "user_name" },
    { headerName: "소속", field: "user_company" },
    { headerName: "담당자", field: "hongbo_user_name" },
    {
      headerName: "작성일",
      field: "create_time",
      valueGetter: ({ row }: { row: DataRow }) =>
        formatCreateTime(row.create_time),
    },
  ];

  return (
    <>
      <div className="container flex">
        <div className="list-table">
          <div className="title-wrap">
            <h2>보도자료 현황</h2>
            <div className="search-date-wrap">
              <div className="search-date-wrap">
                <input
                  type="date"
                  value={
                    fromDate && fromDate.isValid()
                      ? fromDate.format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) => setFromDate(dayjs(e.target.value))}
                  required
                />
                <span>~</span>
                <input
                  type="date"
                  value={
                    toDate && toDate.isValid()
                      ? toDate.format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) => setToDate(dayjs(e.target.value))}
                  required
                />
              </div>
              <select
                id="stats-option"
                value={selectedStatsOption}
                onChange={handleSelectStatsChange}
              >
                <option value="all">전체</option>
                <option value="CREATE">작성중</option>
                <option value="HONGBO_ING">홍보실 접수 대기</option>
                <option value="HONGBO_OK">홍보실 접수</option>
                <option value="RETURNED">반려</option>
                <option value="REJECTED">보완 요청</option>
                <option value="COMPLETE">완료</option>
              </select>
              <select
                id="question-option"
                value={selectedQuestionOption}
                onChange={handleSelectOptionChange}
              >
                <option value="title">제목</option>
                <option value="category">카테고리</option>
                <option value="result">내용</option>
                <option value="hongbo_user_name">담당자</option>
                <option value="user_name">작성자</option>
              </select>
              <div className="srch-date-input-wrap">
                <input
                  type="text"
                  placeholder="검색어 입력..."
                  value={question}
                  onChange={(e) => {
                    setQuestion(e.target.value);
                  }}
                />
              </div>
              <img
                src="/images/excel-icon.png"
                alt="EXCEL"
                style={{
                  width: "40px",
                  height: "40px",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
                onClick={() => downloadExcel(sortedData, columns)}
              />
            </div>
          </div>

          <div className="table-wrap">
            <table>
              <colgroup>
                <col width="120px" />
                <col width="120px" />
                <col className="dynamic-width" />
                <col width="120px" />
                <col width="120px" />
                <col width="120px" />
                <col width="120px" />
                <col width="150px" />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                    >
                      <span
                        style={{
                          width: "100%",
                          textAlign: "center",
                          marginLeft: "10px",
                        }}
                      >
                        순번
                      </span>
                    </div>
                  </th>
                  <th>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                    >
                      <span
                        style={{
                          width: "100%",
                          textAlign: "center",
                          marginLeft: "10px",
                        }}
                      >
                        카테고리
                      </span>
                    </div>
                  </th>
                  <th onClick={() => requestSort("title")}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                    >
                      <span
                        style={{
                          width: "100%",
                          textAlign: "center",
                          marginLeft: "10px",
                        }}
                      >
                        기사제목
                      </span>
                      <div style={{ marginRight: "10px" }}>
                        {sortConfig?.key === "title" ? (
                          sortConfig.direction === "ascending" ? (
                            <NorthIcon style={{ fontSize: "1rem" }} />
                          ) : (
                            <SouthIcon style={{ fontSize: "1rem" }} />
                          )
                        ) : (
                          <ImportExportIcon style={{ fontSize: "1rem" }} />
                        )}
                      </div>
                    </div>
                  </th>
                  <th onClick={() => requestSort("press_status")}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                    >
                      <span
                        style={{
                          width: "100%",
                          textAlign: "center",
                          marginLeft: "10px",
                        }}
                      >
                        진행사항
                      </span>
                      <div style={{ marginRight: "10px" }}>
                        {sortConfig?.key === "press_status" ? (
                          sortConfig.direction === "ascending" ? (
                            <NorthIcon style={{ fontSize: "1rem" }} />
                          ) : (
                            <SouthIcon style={{ fontSize: "1rem" }} />
                          )
                        ) : (
                          <ImportExportIcon style={{ fontSize: "1rem" }} />
                        )}
                      </div>
                    </div>
                  </th>
                  <th onClick={() => requestSort("user_name")}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                    >
                      <span
                        style={{
                          width: "100%",
                          textAlign: "center",
                          marginLeft: "10px",
                        }}
                      >
                        작성자
                      </span>
                      <div style={{ marginRight: "10px" }}>
                        {sortConfig?.key === "user_name" ? (
                          sortConfig.direction === "ascending" ? (
                            <NorthIcon style={{ fontSize: "1rem" }} />
                          ) : (
                            <SouthIcon style={{ fontSize: "1rem" }} />
                          )
                        ) : (
                          <ImportExportIcon style={{ fontSize: "1rem" }} />
                        )}
                      </div>
                    </div>
                  </th>
                  <th onClick={() => requestSort("user_company")}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                    >
                      <span
                        style={{
                          width: "100%",
                          textAlign: "center",
                          marginLeft: "10px",
                        }}
                      >
                        소속
                      </span>
                      <div style={{ marginRight: "10px" }}>
                        {sortConfig?.key === "user_company" ? (
                          sortConfig.direction === "ascending" ? (
                            <NorthIcon style={{ fontSize: "1rem" }} />
                          ) : (
                            <SouthIcon style={{ fontSize: "1rem" }} />
                          )
                        ) : (
                          <ImportExportIcon style={{ fontSize: "1rem" }} />
                        )}
                      </div>
                    </div>
                  </th>
                  <th>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                    >
                      <span
                        style={{
                          width: "100%",
                          textAlign: "center",
                          marginLeft: "10px",
                        }}
                      >
                        담당자
                      </span>
                    </div>
                  </th>
                  <th onClick={() => requestSort("create_time")}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        boxSizing: "border-box",
                      }}
                    >
                      <span
                        style={{
                          width: "100%",
                          textAlign: "center",
                          marginLeft: "10px",
                        }}
                      >
                        작성일
                      </span>
                      <div style={{ marginRight: "10px" }}>
                        {sortConfig?.key === "create_time" ? (
                          sortConfig.direction === "ascending" ? (
                            <NorthIcon style={{ fontSize: "1rem" }} />
                          ) : (
                            <SouthIcon style={{ fontSize: "1rem" }} />
                          )
                        ) : (
                          <ImportExportIcon style={{ fontSize: "1rem" }} />
                        )}
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {sortedData
                  ?.slice(
                    (currentPage - 1) * NOTICE_PER_PAGE,
                    currentPage * NOTICE_PER_PAGE
                  )
                  ?.map((item: any, index: number) => (
                    <tr
                      className={`active ${
                        hoveredRowIndex === index ? "hoveredRow" : ""
                      }`}
                      key={index}
                      onMouseEnter={() => setHoveredRowIndex(index)} // 마우스 올릴 때
                      onMouseLeave={() => setHoveredRowIndex(-1)} // 마우스 떠날 때
                      onClick={() => {
                        navigate("/makePress/" + item.sk);
                      }}
                    >
                      <td className="text-truncate100">{item.sk}</td>
                      <td className="text-truncate100">{item.category}</td>
                      <td className="text-truncate200">{item.title}</td>

                      <td className="text-truncate100">
                        <div
                          className={`center-align-div ${
                            item.press_status === "HONGBO_ING" &&
                            item.hongbo_user_name
                              ? "HONGBO_OK"
                              : item.press_status === "HONGBO_ING"
                              ? "HONGBO_ING"
                              : item.press_status
                          }`}
                        >
                          {item.press_status === "HONGBO_ING" &&
                          item.hongbo_user_name
                            ? "홍보실 접수"
                            : statusTextMap[item.press_status] ||
                              "상태 정보 없음"}
                        </div>
                      </td>

                      <td className="text-truncate100">{item.user_name}</td>
                      <td className="text-truncate100">{item.user_company}</td>
                      <td className="text-truncate100">
                        {item.hongbo_user_name}
                      </td>
                      <td className="text-truncate120">
                        {formatCreateTime(item.create_time)}
                      </td>
                    </tr>
                  )) ?? (
                  <tr>
                    <td>데이터가 없습니다.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            <Stack spacing={2} mt={4}>
              <Pagination
                count={Math.ceil(sortedData.length / NOTICE_PER_PAGE)} // 전체 페이지 수 계산
                page={currentPage}
                siblingCount={3}
                onChange={handlePageChange}
                shape="rounded"
              />
            </Stack>
          </div>
        </div>
      </div>

      <Toast ref={toastRef} />
      <LoadingCircle loading={isLoading} />
    </>
  );
};

export default MyTotalList;
